"use strict"

// element-qsa-scope (polyfill)
import elementQSAScope from "element-qsa-scope";

// element-resize-event
import elementResizeEvent from "element-resize-event";
window.elementResizeEvent = elementResizeEvent;

// moment
import moment from "moment";

window.moment = moment;

// SimpleMDE (Markdown Editor)
import SimpleMDE from "simplemde";
window.SimpleMDE = SimpleMDE;

// tagify
import Tagify from "@yaireo/tagify";

window.Tagify = Tagify;

// perfect-scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

// showdown
import Showdown from 'showdown';
window.Showdown = Showdown;

// simplelightbox
import SimpleLightbox from 'simplelightbox/dist/simple-lightbox.modules.js';
window.SimpleLightbox = SimpleLightbox;
window.addEventListener('load', (event) => {
    document.querySelectorAll('*[data-lightbox]').forEach( (e) => {
        new SimpleLightbox(e);
    });
});

// jscolor
import jscolor from '@eastdesire/jscolor';
window.jscolor = jscolor;

// Easepick
import { easepick } from '@easepick/bundle';
import { TimePlugin } from '@easepick/time-plugin';
window.easepick = easepick;
window.easepickPlugins = {
    TimePlugin: TimePlugin
};

window.JSONbig = require('json-bigint')({ useNativeBigInt: true });
